import React from "react"

import {
  useI18next,
} from "gatsby-plugin-react-i18next"

import Layout from "../components/layout"
import SEO from "../components/seo"
import AboutUsContent from "../components/about-us-content"



const AboutUsPage = () => {
  const {t} = useI18next();
  return (
    <Layout>
      <SEO title={t("MENU.ABOUT-US")} />
      <AboutUsContent />
    </Layout>
  )
}


export default AboutUsPage