import React from 'react';
import { graphql, useStaticQuery } from "gatsby";
import {
    Link,
    Trans,
    useTranslation,
    useI18next,
    I18nextContext
} from "gatsby-plugin-react-i18next"

import styled from "styled-components"

import SectionHeader from './section-header';
import Avatar from '@material-ui/core/Avatar';

import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';

import CardActions from '@material-ui/core/CardActions';
import Button from '@material-ui/core/Button';
import CloseIcon from '@material-ui/icons/Close';

const Container = styled.div`
    width: 100%;
    // padding-top: 60px;
    text-align: center;
    // background: #ACD433;
    // background: #2c466b;
    // color: wheat;
    // margin-top: 15px;
    margin: 0 auto;
    
    @media screen and (max-width: 1220px) {
        margin-top: 0;
        padding-top: 0;
    }
`

const Section = styled.div`
    
`


const ContentWrapper = styled.div`
    margin: 0 auto;
    text-align: center;
    background-color: ${props => props.$backgroundColour};
    color: ${props => props.$colour};
    padding-top: 60px;
    padding-bottom: 60px;
    
        
    @media screen and (max-width: 1220px) {
        padding-top: 90px;
    }
    
        
    @media screen and (max-width: 767px) {
        padding-top: 73px;
    }
`

const ContentInner = styled.div`
    width: 73.306%;
    margin: 0 auto;

    &.white-anchor {
        a {
            color: white;
        }
    }

    &.wider {
        width: 100%;
        max-width: 1372px;
    }
    
    @media screen and (max-width: 767px) {
        width: 100%;
    }
     
`

const Content = styled.div`
    margin: 0 auto;
    margin-top: 40px;
    max-width: 90%;
    
    p { 
        
        font-size: 20px;
        line-height: 1.4;
        margin-top: 14px;
        font-weight: 400;
        white-space: pre-wrap;
        margin-bottom: 14px;
        text-align: left;
        
        @media screen and (max-width: 767px) {
            font-size: 18px;
         }
    }
`

const AvatarsContainer = styled.div`
    display: flex;
    justify-content: center;
    text-align: center;

    @media screen and (max-width: 1300px) {
        flex-wrap: wrap;
    }
    
    @media screen and (max-width: 767px) {
        flex-direction: column;
        align-items: center;
    }
`

const AvatarWrapper = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 360px;
    margin: 25px;
    
`

const StyledAvatar = styled(Avatar)`
    width: 160px;
    height: 160px;
    margin-bottom: 20px;
    cursor: pointer;
    
    @media screen and (max-width: 767px) {
        margin-bottom: 0;
    }
`

const Name = styled.h4`
    font-size: 20px;
    text-transform: uppercase;
    cursor: pointer;
`

const Title = styled.p`
    &&& {
        font-size: 18px;
        margin-top: 0;
        &.align-center {
            text-align: center;
        }
    }
`

const Titles = styled.p`
    flex: 1;
    &.align-center {
        // font-size: 20px;
        text-align: center;
    }
`

const LinkButton = styled(Button)`
    color: #fff;
    background-color: #2c466b;
    width: 160px;
    padding: 6px 16px;
    font-size: 0.875rem;
    min-width: 64px;
    box-sizing: border-box;
    font-family: century-gothic,sans-serif;
    font-weight: 500;
    line-height: 1.75;
    border-radius: 4px;
    text-transform: uppercase;
    cursor: pointer;
    
    &:hover {
        // color: white;
        color: #ff8000;
        background-color: #2c466b;
        text-stroke: 1px;
        -webkit-text-stroke: 1px;
        -webkit-text-stroke-color: #ff8000;
        -webkit-text-stroke-width: 1px;
    }
`

const useStyles = makeStyles((theme) => ({
    modal: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: 'rgba(44, 70, 107, 0.3)',
        textAlign: 'left',
        // fontSize: 18,
        '& p': {
            textAlign: 'left'
        }
    },
    paper: {
        position: 'relative',
        maxWidth: 750,
        maxHeight: '80vh',
        overflowY: 'scroll',
        marginTop: 55,
        backgroundColor: theme.palette.background.paper,
        // border: '2px solid #000',
        boxShadow: theme.shadows[5],
        padding: theme.spacing(2, 4, 3),

        '&::-webkit-scrollbar': {
            display: 'none'
        },

        '&:focus': {
            outline: 0,
        },
    },

    modalHeader: {
        display: 'flex',
    },

    modalHeaderInner: {
        marginLeft: 16,
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
    },

    modalTitle: {
        '&&': {
            textAlign: 'left',
        }
    },
    closeIcon: {
        position: 'absolute',
        right: 16,
        cursor: 'pointer',

        '&:hover': {
            color: '#ff8000',
        }
        // right: 0,
    }

}));




const AboutUsContent = () => {
    const { t } = useI18next();
    const context = React.useContext(I18nextContext);
    const language = context.language;

    const classes = useStyles();

    const [open, setOpen] = React.useState(false);

    const [openSandy, setOpenSandy] = React.useState(false);
    const [openFrici, setOpenFrici] = React.useState(false);
    const [openRudolf, setOpenRudolf] = React.useState(false);
    const [openJavier, setOpenJavier] = React.useState(false);

    // const handleSandyOpen = () => {
    //     setOpenSandy(true)
    // }

    // const handleFriciOpen = () => {
    //     setOpenFrici(true)
    // }

    // const handleSandyClose = () => {
    //     setOpenSandy(false)
    // }

    // const handleFriciClose = () => {
    //     setOpenFrici(false)
    // }

    const setModalStatus = (person, status) => {
        switch (person) {
            case 'sandy':
                setOpenSandy(status)
                break;
            case 'frici':
                setOpenFrici(status)
                break;
            case 'rudolf':
                setOpenRudolf(status)
                break;
            case 'javier':
                setOpenJavier(status)
                break;
        }
    }

    function getPhotoUrl(nickname) {
        if (nickname === "frici") {
            return data.frigyes.publicURL
        } else if (nickname === "sandy") {
            return data.sandy.publicURL ? data.sandy.publicURL : null
        } else if (nickname === "rudolf") {
            return data.rudolf.publicURL ? data.rudolf.publicURL : null
        } else if (nickname === "javier") {
            return data.javier.publicURL ? data.javier.publicURL : null
        } else {
            return null
        }
    }

    function getModalStatus(nickname) {
        if (nickname === "frici") {
            return openFrici
        } else if (nickname === "sandy") {
            return openSandy
        } else if (nickname === "rudolf") {
            return openRudolf
        } else if (nickname === "javier") {
            return openJavier
        } else {
            return false
        }
    }





    const data = useStaticQuery(graphql`
    query {
        sandy: file(relativePath: {eq: "sandy-vaci.jpg"}, publicURL: {}) {
            id
            publicURL
        }
        frigyes: file(relativePath: {eq: "endersz--frigyes.jpg"}, publicURL: {}) {
            id
            publicURL
        }
        rudolf: file(relativePath: {eq: "rudolf--vogl.jpg"}, publicURL: {}) {
            id
            publicURL
        }
        javier: file(relativePath: {eq: "javier--monedero.jpg"}, publicURL: {}) {
            id
            publicURL
        }

        aboutUs: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(about-us)/"  }}) {
            edges {
                node {
                    frontmatter {
                        title
                        subtitle
                        category
                        language
                    }
                    html
                }
            }
        }

        ourTeam: allMarkdownRemark(filter: {fileAbsolutePath: {regex: "/(our-team)/"  }} sort: {fields: frontmatter___weight, order: DESC}) {
          edges {
            node {
              frontmatter {
                name
                nick
                title
                titles
                language
              }
              html
              id
            }
          }
        }
    }
  `)



    // const avatarPhotos = {
    //     "frici": data.frigyes.publicURL,
    //     "sandy": data.sandy.publicURL,
    //     "rudolf": data.rudolf.publicURL,
    //     "javier": data.javier.publicURL
    // }

    let team = data.ourTeam.edges
    let teamFilteredByLanguage = team.filter(member => member.node.frontmatter.language == language)

    let aboutUs = data.aboutUs.edges
    let aboutUsFilteredByLanguage = aboutUs.filter(item => item.node.frontmatter.language == language)

    return (

        <Container>
            <ContentWrapper id="mission" >
                <SectionHeader id="section-header" title={t("ABOUT-US.MISSION")} subtitle={t("ABOUT-US.MISSION-SUBTITLE")} withBackground={false} />
                <Content>
                    <ContentInner>
                        {
                            aboutUsFilteredByLanguage.map((item, index) => {
                                if (item.node.frontmatter.category == "mission") {
                                    return (
                                        <div key={index} dangerouslySetInnerHTML={{ __html: item.node.html }} />
                                    )
                                }
                            })
                        }
                    </ContentInner>
                </Content>
            </ContentWrapper>
            <ContentWrapper id="approach" $backgroundColour="rgba(44,70,107,1)" $colour="wheat">
                <SectionHeader title={t("ABOUT-US.APPROACH")} subtitle={t("ABOUT-US.APPROACH-SUBTITLE")} dividerColour="white" withBackground={false} />
                <Content>
                    <ContentInner className="white-anchor">
                        {
                            aboutUsFilteredByLanguage.map((item, index) => {
                                if (item.node.frontmatter.category == "approach") {
                                    return (
                                        <div key={index} dangerouslySetInnerHTML={{ __html: item.node.html }} />
                                    )
                                }
                            })
                        }
                    </ContentInner>
                </Content>
            </ContentWrapper>
            {/* <ContentWrapper id="our-team" >
                    <SectionHeader title={t("ABOUT-US.OUR-TEAM")} subtitle={t("ABOUT-US.OUR-TEAM-SUBTITLE")} dividerColour="wheat"/>
                    <Content>
                        <ContentInner>
                            <AvatarsContainer>
                                {
                                    teamFilteredByLanguage.map((member, index)=>{
                                        return(
                                            <React.Fragment key={index}>
                                            <AvatarWrapper >
                                                <StyledAvatar onClick={member.node.frontmatter.nick == "sandy" ? handleSandyOpen : handleFriciOpen} src={member.node.frontmatter.nick == "sandy" ? data.sandy.publicURL : data.frigyes.publicURL} />
                                                <Name onClick={member.node.frontmatter.nick == "sandy" ? handleSandyOpen : handleFriciOpen}>{member.node.frontmatter.name}</Name>
                                                <Titles className="align-center">{member.node.frontmatter.titles}</Titles>
                                     
                                                <LinkButton href="" onClick={member.node.frontmatter.nick == "sandy" ? handleSandyOpen : handleFriciOpen} size="small">{t("ABOUT-US.LEARN-MORE")}</LinkButton>
                                           
                                            </AvatarWrapper>
                                                <Modal
                                                    aria-labelledby="transition-modal-title"
                                                    aria-describedby="transition-modal-description"
                                                    className={classes.modal}
                                                    open={member.node.frontmatter.nick == "sandy" ? openSandy : openFrici}
                                                    onClose={member.node.frontmatter.nick == "sandy" ? handleSandyClose : handleFriciClose}
                                                    closeAfterTransition
                                                    BackdropComponent={Backdrop}
                                                    BackdropProps={{
                                                      timeout: 500,
                                                    }}
                                                  >
                                                    <Fade in={member.node.frontmatter.nick == "sandy" ? openSandy : openFrici}>
                                                      <div className={classes.paper}>
                                                        <CloseIcon className={classes.closeIcon} onClick={member.node.frontmatter.nick == "sandy" ? handleSandyClose : handleFriciClose}/>
                                                        <div className={classes.modalHeader}>
                                                            <StyledAvatar src={member.node.frontmatter.nick == "sandy" ? data.sandy.publicURL : data.frigyes.publicURL} />
                                                            <div className={classes.modalHeaderInner}>
                                                                <Name>{member.node.frontmatter.name}</Name>
                                                                <Titles className={classes.modalTitle}>{member.node.frontmatter.titles}</Titles>
                                                            </div>
                                                        </div>    
                                                        <div dangerouslySetInnerHTML={{__html: member.node.html}} />
                                                      </div>
                                                    </Fade>
                                                </Modal>
                                            </React.Fragment>
                                        )
                                    })
                                }
                            </AvatarsContainer>
                          
                        </ContentInner>
                    </Content>
            </ContentWrapper> */}
            <ContentWrapper id="our-team" >
                <SectionHeader title={t("ABOUT-US.OUR-TEAM")} subtitle={t("ABOUT-US.OUR-TEAM-SUBTITLE")} dividerColour="wheat" />
                <Content>
                    <ContentInner className="wider">
                        <AvatarsContainer>
                            {
                                teamFilteredByLanguage.map((member, index) => {
                                    return (
                                        <React.Fragment key={index}>
                                            <AvatarWrapper >
                                                <StyledAvatar onClick={() => { setModalStatus(member.node.frontmatter.nick, true) }} src={getPhotoUrl(member.node.frontmatter.nick)} />
                                                <Name onClick={() => { setModalStatus(member.node.frontmatter.nick, true) }}>{member.node.frontmatter.name}</Name>
                                                <Title className="align-center">{member.node.frontmatter.title}</Title>
                                                <Titles className="align-center">{member.node.frontmatter.titles}</Titles>

                                                <LinkButton href="" onClick={() => { setModalStatus(member.node.frontmatter.nick, true) }} size="small">{t("ABOUT-US.LEARN-MORE")}</LinkButton>
                                            </AvatarWrapper>
                                            <Modal
                                                aria-labelledby="transition-modal-title"
                                                aria-describedby="transition-modal-description"
                                                className={classes.modal}
                                                open={getModalStatus(member.node.frontmatter.nick)}
                                                onClose={() => { setModalStatus(member.node.frontmatter.nick, false) }}
                                                closeAfterTransition
                                                BackdropComponent={Backdrop}
                                                BackdropProps={{
                                                    timeout: 500,
                                                }}
                                            >
                                                <Fade in={getModalStatus(member.node.frontmatter.nick)}>
                                                    <div className={classes.paper}>
                                                        <CloseIcon className={classes.closeIcon} onClick={() => { setModalStatus(member.node.frontmatter.nick, false) }} />
                                                        <div className={classes.modalHeader}>
                                                            <StyledAvatar src={getPhotoUrl(member.node.frontmatter.nick)} />
                                                            <div className={classes.modalHeaderInner}>
                                                                <Name>{member.node.frontmatter.name}</Name>
                                                                <Title className={classes.modalTitle}>{member.node.frontmatter.title}</Title>
                                                                <Titles className={classes.modalTitle}>{member.node.frontmatter.titles}</Titles>
                                                            </div>
                                                        </div>
                                                        <div dangerouslySetInnerHTML={{ __html: member.node.html }} />
                                                    </div>
                                                </Fade>
                                            </Modal>
                                        </React.Fragment>
                                    )
                                })
                            }
                        </AvatarsContainer>

                    </ContentInner>
                </Content>
            </ContentWrapper>
        </Container>


    )
}

export default AboutUsContent